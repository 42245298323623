<template>
  <div>
    <AvatarDetailEditForm   title="Bewerk avatar" :item-name="userName"
                            confirmationMessage="Avatar werd succesvol gewijzigd"
                            :detailViewRoute="{name: routeNames.USER_DETAIL, params: { userId: userId}}" :delete-avatar="'usersModule/removeUserAvatar'"
                            update-store-action-name='usersModule/updateUserAvatar' :update-store-action-payload="{userId: this.userId}"
                            :id="userId" :promises.sync="promises" :retrieve-avatar="'usersModule/getUserAvatar'" :SASKey="SASKey">
    </AvatarDetailEditForm>
  </div>

</template>
<script>

import routeNames from "@/router/RouteNames";
import AvatarDetailEditForm from "@/components/shared/AvatarDetailEditForm";


export default {
  name: "UserDetailEditAvatars",
  components: {
    AvatarDetailEditForm
  },
  data() {
    return {
      routeNames: routeNames,
      userId: this.$route.params.userId,
      userName: "",
      promises: [
        this.$store.dispatch("fileExportModule/fetchSASKey")
            .then(result => this.SASKey = result),
      ],
      SASKey: null,
    }
  },
  created() {
    this.getUserName()
  },
  computed: {
    getSaveButtonContent() {
      return 'Verwijder'
    }
  },
  methods: {
    getUserName(){
      this.$store.dispatch('usersModule/fetchUserName', this.userId)
          .then((result) => {this.userName = result.name})
    }
  }
}
</script>